<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('ledger_detail_jbxx', { id: $route.query.id })">基本信息</span>
      <span @click="toUrl('ledger_detail_jsxx', { id: $route.query.id })">结算信息</span>
      <span @click="toUrl('ledger_detail_glqd', { id: $route.query.id })">关联渠道</span>
      <span @click="toUrl('ledger_detail_dzzb', { id: $route.query.id })">电子账簿</span>
      <span @click="toUrl('ledger_detail_cpxx', { id: $route.query.id })" id="fzcolor">产品信息</span>
      <span @click="toUrl('ledger_detail_bccl', { id: $route.query.id })">补充材料</span>
    </div>
    <div v-auth="'ELECTRLED:MCHCOM:MCHLIST:PRODUCT'"> 
        <div class="desc">
          <h5 class="jsxx">通道产品信息</h5>
        </div>
        <div class="tab1">
          <table class="tab">
            <tr class="tab_title">
              <td>
                <p>商户名称</p>
              </td>
              <td>
                <p>产品类型</p>
              </td>
              <td>
                <p>费率(%)</p>
              </td>
              <!-- <td>
                <p>操作</p>
              </td> -->
            </tr>
            <tr v-for="(v, i) in TDdata" :key="i">
              <td>
                <p>{{ mchDetail.mchName }}</p>
              </td>
              <td>
                <p>{{ v.type=="BANK_ACCOUNT_TRANSFER_RECHARGE" ? "汇管家-入金" : "汇管家-出金" }}</p>
              </td>
              <td>
                <p>{{ v.feeRate / 100 }}</p>
              </td>
              <!-- <td>
                <p>
                  <i class="lianjie" @click="editTDinfo(v, i)">编辑</i>
                </p>
              </td> -->
            </tr>
          </table>
        </div>
        <!-- <h6 class="xzjsxx" @click="addTDinfo" v-if="TDdata == '' || TDdata.length<=1" v-auth="'ELECTRLED:MCHCOM:MCHLIST:PRODUCT/ADD'"><span>+</span>新增通道产品信息</h6> -->

        <el-dialog title="通道产品信息" :visible.sync="overTDDataShow" width="490px" :close-on-click-modal="false">
          <ul class="overAccount_ul">
            <li class="ul_left">
              <div class="overAccount_ul_title"><i>*</i>商户名称</div>
              <el-input
                v-model="mchDetail.mchName"
                maxlength="128"
                placeholder="商户名称不可修改"
                class="width100"
                disabled
              ></el-input>
              <div class="overAccount_ul_title"><i>*</i>费率(%)</div>
              <el-input
                  type="number"
                  onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
                  min="0"
                  max="100"
                  v-model="fromTDData.feeRate"
                  placeholder="输入费率"
                  @input="inputnumTD"
                ></el-input>
            </li>
            <li class="ul-right">
              <div class="overAccount_ul_title"><i>*</i>产品类型</div>
              <el-select
                class="inputs"
                v-model="fromTDData.type"
                placeholder="选择产品类型"
                tabindex="5"
              >
                <el-option
                  v-for="(v, i) in tdBrokerageType"
                  :key="i"
                  :label="v.title"
                  :value="v.value"
                  :disabled="v.disabled"
                ></el-option>
              </el-select>
            </li>
          </ul>
          <span slot="footer" class="dialog-footer">
            <el-button class="exitBt" @click="overTDDataShow = false"
              >取 消</el-button
            >
            <el-button class="addBt" :disabled="buttonFlag" @click="sureTDinfo"
              >确 定</el-button
            >
          </span>
        </el-dialog>

    </div>
  </div>
</template>
<script>
import {ledgerDetail} from "@/api/user/shopManager.js"; 
import {  accMul } from "@/utils/common.js";
import md5 from "js-md5";
export default {
  data() {
    return {
      mchDetail:"",
      overAccountShow: false, //平台产品信息新增/修改展示
      overTDDataShow: false, //通道产品信息新增/修改展示
      fromTDData:{
        type: "", //类型
        feeRate: "",//费率
      },
      formData: {
        type: "", //类型
        feeRate: "",//费率
        feeCost:"",//成本(元/笔) 
        status:""//状态       
      },
      TDdata:[],
      tabData: [],
      editIndex: "",
      buttonFlag: false, //防止重复点击
      tdBrokerageType:[
        {
          title: "汇管家-入金",
          value: "BANK_ACCOUNT_TRANSFER_RECHARGE",
          disabled:false,
        },
        {
          title: "汇管家-出金",
          value: "BANK_ACCOUNT_WITHDRAWAL",
          disabled:false,
        },
      ],
      brokerageType: [
        {
          title: "出金",
          value: "1",
          disabled:false,
        },
        {
          title: "代付",
          value: "2",
          disabled:false,
        },
      ],
      md5,
    };
  },
  mounted() {

  },
  created() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      ledgerDetail(this.$route.query.id).then((res) => {
        if (res.resultStatus) { 
          this.mchDetail = res.resultData.mchDetail;         
          if(res.resultData.mchFeeAccount != null){            
            this.tabData = res.resultData.mchFeeAccount;
          }else{
            this.tabData = "";
          }           
          if(res.resultData.mchFeeAccountHj != null){            
            this.TDdata = res.resultData.mchFeeAccountHj;
          }else{
            this.TDdata = "";
          }   
                 
        }
      });
    },
    // 修改状态
    setStatus(value) {
      let data = JSON.parse(JSON.stringify(value));
      if(data.status == true){
        data.status = 1
      }else{
        data.status = 0
      }
      mchFeeEdit(this.$route.query.id, data).then((res) => {
        if (!res) {
          value.status = value.status ? false : true;
        }
        this.getDetail(this.$route.query.id);  
      });
    },
    // 添加通道
    addTDinfo(){
      this.fromTDData = {
        type: "", //类型
        feeRate: "",//费率
      };
      if(this.TDdata != ""){
        for(let j = 0 ; j < this.TDdata.length ; j++){
          const type =  this.TDdata[j].type;
          for(let i = 0 ; i < this.tdBrokerageType.length ; i++){
            if(type == this.tdBrokerageType[i].value){
              this.tdBrokerageType[i].disabled = true;
            }else{
              this.tdBrokerageType[i].disabled = false;
            }
          } 
        }
        
      }else{
        for(let i = 0 ; i < this.tdBrokerageType.length ; i++){
          this.tdBrokerageType[i].disabled = false;
        } 
      }
      this.editIndex = null;
      this.overTDDataShow = true;
    },
    sureTDinfo() {
      let data = JSON.parse(JSON.stringify(this.fromTDData));
      if (!data.type) {
        this.$message.error("产品类型必选");
        return false;
      }
      if (!data.feeRate && data.feeRate !== 0) {
        this.$message.error("费率必填，且小于等于100");
        return false;
      }
      if(data.feeRate > 100 ){
        this.$message.error("费率必填，且小于等于100");
        return false;
      }
	    this.buttonFlag=true
      data.feeRate = accMul(data.feeRate , 100);
      mchFeeEditHj(this.$route.query.id, data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getDetail(this.$route.query.id);  
          this.overTDDataShow = false;
        }
        setTimeout(() => {
            this.buttonFlag = false;
        }, 1000);
      });
    }, 
    // 修改
    editTDinfo(formData, index) {
      if(this.mchDetail.checkStatus == 'PASS') {
        this.$message.error("审核状态已通过,不可编辑");
        return false;
      }
      this.fromTDData = JSON.parse(JSON.stringify(formData)); 
      if(this.tabData != ""){
        for(let i = 0 ; i < this.tdBrokerageType.length ; i++){
          console.log(this.fromTDData.type)
            if(this.fromTDData.type == this.tdBrokerageType[i].value){
              console.log( this.tdBrokerageType[i].value)
              this.tdBrokerageType[i].disabled = false;
            }else{
              this.tdBrokerageType[i].disabled = true;
            }
        } 
      }      
      this.fromTDData.feeRate = this.fromTDData.feeRate / 100;
      this.editIndex = index;
      this.overTDDataShow = true;
    }, 
    inputnumTD(e){
      if(e == -1){
        this.fromTDData.feeRate = "";
      }else{
        if(!(/^(\d?)+(\.\d{0,2})?$/.test(e))){  
          e = e.substring(0, e.length - 1); 
          this.$message.error("请输入小数点后两位");
        }
        this.fromTDData.feeRate = e;
      }
    },
    // 输入金额位数提示    
    inputLength(e) {
      if (!(/^(\d?)+(\.\d{0,2})?$/.test(e))) {
        e = e.substring(0, e.length - 1);
        this.$message.error("请输入数字，且只能保留小数点后两位");
      }
      if(e.length > 8){
        e = e.substring(0, e.length - 1); 
        this.$message.error("金额超过最大长度");
      }
      this.formData.feeCost = e;
    },
    // 页面跳转
    toUrl(name,query) {
      this.$router.push({
        name: name,
        query: {
          ...query,
        },
      });
    },
  },
  beforeDestroy() {

  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: var(--themeColor);
  border-color: var(--themeColor);
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: var(--title);
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
}

.overAccount_ul_title i {
  color: var(--themeColor);
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>